import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from '@angular/router';
import { moduleType } from "../../shared/models/module.type";
import { GeneralComponent } from "../../components/general/general.component";
import { TranslationService } from "../../shared/services/translation/translation.service";
import { language } from "../../shared/models/language.model";
import { QuestionsComponent } from "../../components/questions/questions.component";
import { QuoterRequest2Service } from "../../shared/services/quote-request/quote-request2.service";
import { ImageService } from "../../shared/services/images/image.service";
import { TranslateService } from "@ngx-translate/core";
import { AppRoutingModule } from 'src/app/app-routing.module';
import { QuoteStorageService } from "../../shared/services/quote-storage/quote-storage.service";
import { SessionService } from "../../shared/services/session/session.service";
import { ErrorMessageService } from "../../shared/services/error-message/error-message.service";
import { AnalyticsTrackingService } from "../../shared/services/tracking/tracking.service";

@Component({
    selector: "app-homeowner",
    templateUrl: "./homeowner.component.html",
    styleUrls: ["./homeowner.component.scss"]
})
export class HomeownerComponent implements OnInit {
    @ViewChild(GeneralComponent) general: GeneralComponent;
    @ViewChild(QuestionsComponent) questions: QuestionsComponent;
    policytype = moduleType.homeowner;
    isComparePage: boolean;
    showQuestions = false;
    lang = language;
    hello;
    yesCompare;

    constructor(
        private route: ActivatedRoute,
        public translationService: TranslationService,
        private quoterRequestService: QuoterRequest2Service,
        private imageService: ImageService,
        private translate: TranslateService,
        private quoteStorageService: QuoteStorageService,
        private sessionService: SessionService,
        private errorMessageService: ErrorMessageService,
        private appRouting: AppRoutingModule,
        private tracking: AnalyticsTrackingService,
        private router: Router) {

        //need to know which route/view to save/restore
        const url = "/homeowner/" + (this.route.snapshot.routeConfig.path.endsWith("/questions") ? "questions" : "general");
        this.sessionService.listenUnload(url, function () {
            this.quoteStorageService.SaveState(this.sessionService.getRelativeUrl())
                .SaveRequest(this.quoterRequestService.getCurrentQuoterRequest())
                .SaveLanguage(this.translationService.currentLanguage);
        }.bind(this));

        this.errorMessageService.moveTop();

        this.translationService.onLangChange.subscribe((event: any) => {
            if (this.general) {
                this.general.languageChanged();
            } 
        });
    }

    goToQuestionPage($event: any) {
        if ($event === "true") {
            //this.tracking.trackLocationChange("homeowner/questions");
            this.router.navigate(["homeowner/questions"]);
            this.showQuestions = true;
        }
    }

    goToGeneralPage($event: any) {
        if ($event === "true") {
            this.router.navigate(["homeowner/general"]);
            this.showQuestions = false;
        }
    }

    ngOnInit(): void {

        if (this.route.snapshot.queryParams['showGeneralPage'] || this.route.snapshot.routeConfig.path.endsWith("/questions")) {
            console.log("lets watch");
            const showQuestions = this.route.snapshot.queryParams['showGeneralPage'] === "true" || this.route.snapshot.routeConfig.path.endsWith("/questions"); 
            const reset = this.route.snapshot.queryParams['reset'] === "true";
            this.showQuestions = (showQuestions || reset);
            this.isComparePage = (showQuestions || reset);
            this.yesCompare = this.parseYesCompare(this.route.snapshot.queryParams['yesCompare']);
        }
        this.quoterRequestService.setSettingForQuestionPage(this.quoterRequestService.getCurrentQuoterRequest());
        this.hello = this.translate.instant("GLOBAL.CONDO_OWNERS");
    }

    parseYesCompare(input: any) {
        if (input === 'true') {
            return true;
        }

        if (input === 'false') {
            return false;
        }

        return undefined;
    }

    checkComparison() {
        const item = this.quoteStorageService.GetCompareItem();

        if (item && item === 'true') {
            this.goToQuestionPage('true');
        }
    }

    headerTitle(): string {
        const calcInput = this.quoterRequestService.getCalcInputData();

        let title = this.translate.instant("HOMEOWNERS.HEADER_TITLE");
        if (!calcInput) return title;
        if (!calcInput.PersonalPropertyDeductible) {
            title = this.translate.instant("GLOBAL.Homeowners");
        }
        else if (calcInput.PersonalPropertyDeductible !== 0 &&
            calcInput.PersonalPropertyDeductible !== null) {
            title = this.translate.instant("GLOBAL.Homeowners Choice");
        }

        return title;
    }

    useLanguage(selectedlanguage: string) {
        this.translationService.useLanguage(selectedlanguage);
        this.quoteStorageService.SaveLanguage(this.translationService.currentLanguage);
    }

    imageUrl(relative: string) {
        return this.imageService.ImageLoc(relative);
    }
}
