<div class="cea-header-view ng-scope" ui-view="header"></div>
<div class="cea-page" id="cea-page-1" style="padding-top: 0px;" (window:resize)="onResize($event)">
  <div class="cea-main-view" ui-view="" *ngIf="_reload">
    <div class="row compare-area blue-key">
      <div class="cea-col-xs-12">
        <div id="quoteListGroup" class="list-area">
          <div class="compare-container-fluid">
            <div class="row">
              <h2>{{'COMPARE_SEND.HEADLINE' | translate }}</h2>
              <div class="body-copy cea-col-xs-12 margin-bottom-lg padding-y-xs">
                {{'COMPARE_SEND.INSTRUCTIONS.TEXT' | translate }}
                <a class="link-color" href="{{eqaUrl | lowercase}}{{'GLOBAL.PARTICIPATING_INSURER_URL' | translate | lowercase}}"
                   target="_parent">{{'COMPARE_SEND.INSTRUCTIONS.LINK' | translate }}</a>
              </div>
            </div>
            <div class="cea-visible-xxs cea-visible-xss cea-hidden-lg cea-hidden-md cea-hidden-sm cea-hidden-xs cea-top-margin20">
              <div class="row">
                <div class="header-language" style="margin-top: 1px;">
                  <button id="es" class="btn btn-link" style="font-weight:normal"
                          *ngIf="!(this.translationService.currentLanguage === lang.spanish)" (click)="useLanguage(lang.spanish, $event)">
                    en Español
                  </button>
                  <button id="en" class="btn btn-link" style="font-weight:normal"
                          *ngIf="!(this.translationService.currentLanguage === lang.english)" (click)="useLanguage(lang.english, $event)">
                    in English
                  </button>
                  <button id="zh" class="btn btn-link chinese" style="font-weight:normal"
                          *ngIf="!(this.translationService.currentLanguage === lang.chinese)" (click)="useLanguage(lang.chinese, $event)">
                    中文
                  </button>
                </div>

                <div class="panel estimate-panel" style="border: 0; margin-bottom: 0;">
                  <button class="btn btn-block btn-label text-uppercase"
                          style="border-radius: 0; color: #5A5A5A; background-color:#F0F0F0; margin-bottom: 0;">
                    {{'COMPARE_SEND.TAP_NUMBER' | translate }}
                  </button>
                </div>

                <div class="panel estimate-panel" *ngFor="let q of quotes; let i = index">
                  <button class="btn btn-default panel-select-button btn-block"
                          (click)="selectCalculation(i, $event)" [ngClass]="{'active': ids.indexOf(i) > -1}">
                    {{i + 1}}
                  </button>
                  <div class="panel-body">
                    <div class="panel-field-caption">
                      {{'COMPARE_SEND.COMPARE_TABLE.MONTHLY_HEADER' | translate }}
                    </div>
                    <div class="panel-field-value"> ${{q.MonthlyPremium | number:'1.2-2'  }}</div>
                    <div class="panel-field-caption">
                      {{'COMPARE_SEND.COMPARE_TABLE.ANNUALLY_HEADER' | translate }}
                    </div>
                    <div class="panel-field-value"> ${{q.AnnualPremium  | number:'1.2-2'}}</div>
                    <div class="panel-field-caption">
                      {{'COMPARE_SEND.COMPARE_TABLE.POLICY_TYPE_HEADER' | translate }}
                    </div>
                    <div class="panel-field-value"> {{q.PolicyTypeTranslate}}</div>
                    <button class="btn btn-xs panel-action-button"
                            style="margin-right: 7px; margin-top: 20px;"
                            (click)="editCalculation(i, $event)">
                      {{'COMPARE_SEND.COMPARE_TABLE.EDIT_BUTTON' | translate }}
                    </button>
                    <button class="btn btn-xs panel-action-button"
                            (click)="removeCalculation(i, content, $event)">
                      {{'COMPARE_SEND.COMPARE_TABLE.REMOVE_BUTTON' | translate }}
                    </button>
                  </div>
                </div>
                <ng-template #content let-modal>
                  <div class="modal-header">
                    <h3>{{'COMPARE_SEND.CONFIRM_ESTIMATE_DELETE_TITLE' | translate}}</h3>
                  </div>
                  <div class="modal-body">
                    <p>{{'COMPARE_SEND.CONFIRM_ESTIMATE_DELETE' | translate}}</p>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-primary"
                            (click)="yesRemovePolicy(modal)">
                      {{'GLOBAL.YES' | translate}}
                    </button>
                    <button type="button" class="btn btn-accent"
                            (click)="modal.close()">
                      {{'GLOBAL.NO' | translate}}
                    </button>
                  </div>
                </ng-template>

                <div class="cea-col-xxs-12 cea-col-xss-12" *ngIf="this.quotes.length !== 10"
                     style="margin-top: 15px;">
                  <button class="btn btn-block btn-primary" (click)="addNewCompare()">
                    {{'COMPARE_SEND.COMPARE_TABLE.NEW_ESTIMATE_BUTTON' | translate }}
                    <em class="fas fa-plus"></em>
                  </button>
                </div>

                <div class="cea-col-xxs-12 cea-col-xss-12 text-center" *ngIf="this.quotes.length === 10"
                     style="margin-bottom: 15px; padding-top: 0px;">
                  <span class="body-copy-small"><em>{{'COMPARE_SEND.COMPARE_TABLE.MAX_LIMIT' | translate }}</em></span>
                </div>

                <div class="cea-col-xxs-12 cea-col-xss-12">
                  <button class="btn btn-block btn-accent" id="compare-clear-and-start-over-xxs-btn"
                          (click)="clearAndStartOver(false)">
                    {{'COMPARE_SEND.COMPARE_TABLE.START_OVER_BUTTON' | translate }}
                  </button>
                </div>

                <div class="cea-col-xxs-12 cea-col-xss-12">
                  <a class="btn btn-block btn-primary btn-find-agent"
                     href="{{findAgentUrl | lowercase}}"
                     target="_blank" rel="noopener">{{'COMPARE_SEND.FIND_AGENT_BUTTON' | translate }}</a>
                </div>

                <div class="cea-col-xxs-12 cea-col-xss-12">
                  <a class="btn btn-block btn-primary btn-contact-us"
                     href="{{eqaUrl | lowercase}}{{'GLOBAL.CONTACT_URL' | translate | lowercase}}"
                     target="_parent">{{'COMPARE_SEND.EMAIL_SUPPORT' | translate }}</a>
                </div>

                <div class="cea-col-xxs-12 cea-col-xss-12 text-center">
                  <button class="btn btn-link text-uppercase btn-back-to-top"
                          (click)="backToTop()">
                    <u>{{'COMPARE_SEND.BACK_TOP_TOP' | translate }}</u>
                  </button>
                </div>

              </div>
            </div>

            <div class="row cea-visible-lg cea-visible-md cea-visible-sm cea-visible-xs cea-hidden-xxs cea-hidden-xss">
              <div class="estimate-list-column">

                <div class="row">
                  <div class="header-language">
                    <button id="es" class="btn btn-link" style="font-weight:normal"
                            *ngIf="!(this.translationService.currentLanguage === lang.spanish)"
                            (click)="useLanguage(lang.spanish, $event)">
                      en Español
                    </button>
                    <button id="en" class="btn btn-link" style="font-weight:normal"
                            *ngIf="!(this.translationService.currentLanguage === lang.english)"
                            (click)="useLanguage(lang.english, $event)">
                      in English
                    </button>
                    <button id="zh" class="btn btn-link chinese" style="font-weight:normal"
                            *ngIf="!(this.translationService.currentLanguage === lang.chinese)"
                            (click)="useLanguage(lang.chinese, $event)">
                      中文
                    </button>
                  </div>
                </div>


                <div class="compare-container-fluid estimate-group">
                  <div class="row estimate-group-headers">
                    <div class="select-column estimate-group-column-header ng-binding">
                      {{'COMPARE_SEND.COMPARE_TABLE.SELECT_HEADER' | translate }}
                    </div>
                    <div class="monthly-column estimate-group-column-header ng-binding">
                      {{'COMPARE_SEND.COMPARE_TABLE.MONTHLY_HEADER' | translate }}
                    </div>
                    <div class="annually-column estimate-group-column-header ng-binding">
                      {{'COMPARE_SEND.COMPARE_TABLE.ANNUALLY_HEADER' | translate }}
                    </div>
                    <div class="type-column estimate-group-column-header ng-binding">
                      {{'COMPARE_SEND.COMPARE_TABLE.POLICY_TYPE_HEADER' | translate }}
                    </div>
                    <div class="actions-column estimate-group-column-header"
                         style="border-right-width: 0px">
                      &nbsp;
                    </div>
                  </div>
                  <div class="row estimate-group-item" *ngFor="let q of quotes; let i = index">
                    <div class="select-column" [ngClass]="{'selected': ids.indexOf(i) > -1}"
                         (click)="selectCalculation(i, null)">

                      {{i + 1}}
                    </div>
                    <div class="monthly-column">
                      <span class="hide-big">{{'COMPARE_SEND.COMPARE_TABLE.MONTHLY_HEADER' | translate }}</span>
                      ${{q.MonthlyPremium  | number:'1.2-2' }}
                    </div>
                    <div class="annually-column">
                      <span class="hide-big">{{'COMPARE_SEND.COMPARE_TABLE.ANNUALLY_HEADER' | translate }}</span>
                      ${{q.AnnualPremium | number:'1.2-2'}}
                    </div>
                    <div class="type-column">
                      <span class="hide-big">{{'COMPARE_SEND.COMPARE_TABLE.POLICY_TYPE_HEADER' | translate }}</span>
                      {{q.PolicyTypeTranslate}}
                    </div>
                    <div class="actions-column">
                      <button class="btn btn-xs estimate-button"
                              (click)="editCalculation(i, $event)">
                        {{'COMPARE_SEND.COMPARE_TABLE.EDIT_BUTTON' | translate }}
                      </button>
                      <button class="btn btn-xs estimate-button"
                              style="margin-left: 3px !important;"
                              (click)="removeCalculation(i, content, $event)">
                        {{'COMPARE_SEND.COMPARE_TABLE.REMOVE_BUTTON' | translate }}
                      </button>
                    </div>
                  </div>
                  <ng-template #content let-modal>
                    <div class="modal-header">
                      <h3>
                        {{'COMPARE_SEND.CONFIRM_ESTIMATE_DELETE_TITLE' | translate}}
                      </h3>
                    </div>
                    <div class="modal-body">
                      <p>{{'COMPARE_SEND.CONFIRM_ESTIMATE_DELETE' | translate}}</p>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-primary"
                              (click)="yesRemovePolicy(modal)">
                        {{'GLOBAL.YES' | translate}}
                      </button>
                      <button type="button" class="btn btn-accent"
                              (click)="modal.close()">
                        {{'GLOBAL.NO' | translate}}
                      </button>
                    </div>
                  </ng-template>
                  <div class="row no-gutters newEstbtns" style="border-top: 1px solid darkgrey;">
                    <div class="cea-col-sm-6 cea-col-xs-7 text-center" *ngIf="this.quotes.length === 10"
                         style="padding-top: 12px;">
                      <span class="body-copy-small"><em>{{'COMPARE_SEND.COMPARE_TABLE.MAX_LIMIT' | translate }}</em></span>
                    </div>
                    <div class="cea-col-sm-6 cea-col-xs-6">
                      <button class="btn btn-block btn-accent" id="compare-clear-and-start-over-btn"
                              style="font-size:11.5px"
                              (click)="clearAndStartOver(false)">
                        {{'COMPARE_SEND.COMPARE_TABLE.START_OVER_BUTTON' | translate }}
                      </button>
                    </div>
                    <!-- ngIf: additionalQuotesAllowed -->
                    <div class="cea-col-sm-6 cea-col-xs-6"
                         *ngIf="this.quotes.length < 10">
                      <button class="btn btn-block btn-primary" id="compare-add-new-btn"
                              style="font-size:11.5px" (click)="addNewCompare()">
                        {{'COMPARE_SEND.COMPARE_TABLE.NEW_ESTIMATE_BUTTON' | translate }}
                        <em class="fas fa-plus"></em>
                      </button>
                    </div><!-- end ngIf: additionalQuotesAllowed -->
                  </div>
                </div>
              </div>
              <div class="cea-visible-xs cea-col-xs-12"><br><br></div>
              <div class="panels-column cea-visible-lg cea-visible-md cea-visible-sm cea-hidden-xs cea-hidden-xss cea-hidden-xxs">
                <div class="panel">
                  <div class="panel-body">
                    <h2>{{'COMPARE_SEND.SUPPORT_INSURER.TITLE' | translate }}</h2>
                    <p class="body-copy ng-binding" [innerHTML]='supportInsurerTxt'></p>
                  </div>
                  <a class="btn btn-primary btn-lg btn-block margin-bottom"
                     href="{{findAgentUrl | lowercase}}"
                     target="_blank"
                     rel="noopener"
                     (click)="trackAgentClick(findAgentUrl)">{{'COMPARE_SEND.FIND_AGENT_BUTTON' | translate }}</a>
                </div>
                <div class="panel">
                  <div class="panel-body">
                    <h2>{{'COMPARE_SEND.TECHNICAL_SUPPORT.TITLE' | translate }}</h2><br />
                    <p class="body-copy">
                      {{'COMPARE_SEND.TECHNICAL_SUPPORT.TEXT1' | translate }}
                      <a style="color: #1294b2 !important;" href="{{eqaUrl | lowercase}}{{'GLOBAL.BROWSER_SUPPORT_URL' | translate | lowercase}}" target="_parent">
                        {{'COMPARE_SEND.TECHNICAL_SUPPORT.BROWSER_COMPATIBLE' | translate }}
                      </a>
                      {{'COMPARE_SEND.TECHNICAL_SUPPORT.TEXT2' | translate }}
                    </p>
                  </div>
                  <a class="btn btn-primary btn-lg btn-block margin-bottom"
                     href="{{eqaUrl | lowercase}}{{'GLOBAL.CONTACT_URL' | translate | lowercase}}"
                     target="_parent">{{'COMPARE_SEND.EMAIL_SUPPORT' | translate }}</a>
                </div>
              </div>

              <div class="panels cea-hidden-lg cea-hidden-md cea-hidden-sm cea-visible-xs cea-visible-xxs cea-col-xs-12">
                  <div style="display: flex !important">
                      <div class="panel">
                          <div class="panel-body">
                              <h2>{{'COMPARE_SEND.SUPPORT_INSURER.TITLE' | translate }}</h2>
                              <p class="body-copy ng-binding" [innerHTML]='supportInsurerTxt'></p>
                          </div>
                          <a class="btn btn-primary btn-lg btn-block margin-bottom"
                             href="{{findAgentUrl | lowercase}}"
                             target="_blank"
                             rel="noopener"
                             (click)="trackAgentClick(findAgentUrl)">{{'COMPARE_SEND.FIND_AGENT_BUTTON' | translate }}</a>
                      </div>



                      <div class="panel">
                          <div class="panel-body">
                              <h2>{{'COMPARE_SEND.TECHNICAL_SUPPORT.TITLE' | translate }}</h2><br />
                              <p class="body-copy">
                                  {{'COMPARE_SEND.TECHNICAL_SUPPORT.TEXT1' | translate }}
                                  <a class="link-color" href="{{eqaUrl | lowercase}}{{'GLOBAL.BROWSER_SUPPORT_URL' | translate | lowercase}}" target="_parent">
                                      {{'COMPARE_SEND.TECHNICAL_SUPPORT.BROWSER_COMPATIBLE' | translate }}
                                  </a>
                                  {{'COMPARE_SEND.TECHNICAL_SUPPORT.TEXT2' | translate }}
                              </p>
                          </div>
                          <a class="btn btn-primary btn-lg btn-block margin-bottom"
                             href="{{eqaUrl | lowercase}}{{'GLOBAL.CONTACT_URL' | translate | lowercase}}"
                             target="_parent">{{'COMPARE_SEND.EMAIL_SUPPORT' | translate }}</a>
                      </div>
                  </div>
                  </div>
              </div>

          </div>
          <div class="after"></div>
        </div>

        <div id="quoteListGroupOld" class="list-area" style="display:none"></div>


        <div class="cea-details" *ngIf="showTable">
          <div id="details" class="ng-scope details-area">
            <div class="row" id="report-header">
              <div class="cea-col-sm-12 report-header-logo report-header-logo-1">
                <img id="cea-logo" [src]="imageUrl('/files/assets/img/cea-logo-sm.png')"
                     alt="California Earthquake Authority" ng-style="{width: logoWidth }" />
                <div class="details-text">
                  www.earthquakeauthority.com
                </div>
              </div>

              <div class="cea-col-sm-8 report-header-text renterEstiHead report-header-logo-2">
                <h2 *ngIf="policy === 1" class="details-header">
                  {{'COMPARE_SEND.HOMEOWNERS_ESTIMATE' | translate }}
                </h2>
                <h2 *ngIf="policy === 2" class="details-header">
                  {{'COMPARE_SEND.RENTERS_ESTIMATE' | translate }}
                </h2>
                <h2 *ngIf="policy === 3" class="details-header">
                  {{'COMPARE_SEND.CONDO_OWNERS_ESTIMATES' | translate }}
                </h2>
                <h2 *ngIf="policy === 4" class="details-header">
                  {{'COMPARE_SEND.MOBILEHOME_OWNERS_ESTIMATES' | translate }}
                </h2>

                <div *ngIf="policy === 1" class="details-text">
                  {{'COMPARE_SEND.MINIMUM_HOMEOWNER' | translate }}
                </div>
                <div *ngIf="policy === 2" class="details-text">
                  {{'COMPARE_SEND.MINIMUM_RENTER' | translate }}
                </div>
                <div *ngIf="policy === 3" class="details-text">
                  {{'COMPARE_SEND.MINIMUM_CONDO' | translate }}
                </div>
                <div *ngIf="policy === 4" class="details-text">
                  {{'COMPARE_SEND.MINIMUM_MOBILE' | translate }}
                </div>
              </div>
              <div class="cea-col-sm-12 report-header-text renterEstiHead report-header-logo-1">
                <h2 *ngIf="policy === 1" class="details-header">
                  {{'COMPARE_SEND.HOMEOWNERS_ESTIMATE' | translate }}
                </h2>
                <h2 *ngIf="policy === 2" class="details-header">
                  {{'COMPARE_SEND.RENTERS_ESTIMATE' | translate }}
                </h2>
                <h2 *ngIf="policy === 3" class="details-header">
                  {{'COMPARE_SEND.CONDO_OWNERS_ESTIMATES' | translate }}
                </h2>
                <h2 *ngIf="policy === 4" class="details-header">
                  {{'COMPARE_SEND.MOBILEHOME_OWNERS_ESTIMATES' | translate }}
                </h2>

                <div *ngIf="policy === 1" class="details-text">
                  {{'COMPARE_SEND.MINIMUM_HOMEOWNER' | translate }}
                </div>
                <div *ngIf="policy === 2" class="details-text">
                  {{'COMPARE_SEND.MINIMUM_RENTER' | translate }}
                </div>
                <div *ngIf="policy === 3" class="details-text">
                  {{'COMPARE_SEND.MINIMUM_CONDO' | translate }}
                </div>
                <div *ngIf="policy === 4" class="details-text">
                  {{'COMPARE_SEND.MINIMUM_MOBILE' | translate }}
                </div>
              </div>

              <div class="cea-col-sm-4 report-header-logo report-header-logo-2">
                <img id="cea-logo" [src]="imageUrl('/files/assets/img/cea-logo-sm.png')"
                     alt="California Earthquake Authority" ng-style="{width: logoWidth }" />
                <div class="details-text">
                  www.earthquakeauthority.com
                </div>
              </div>
            </div>


            <div id="report-body">
              <div class="address-text ng-binding">
                {{getCompleteAddress()}}
              </div>
            </div>

            <div class="row chosenPol" *ngIf="showCompare()">
              <div class="cea-col-sm-10 cea-col-xs-9">
                <p>
                  {{getYesComparePolicyNameById()}}<br>
                  {{getOppositePolicyNameById()}}<br>
                  <a class="link-color" href="javascript:void()" (click)="policyDiffPop()">
                    {{'COMPARE_SEND.WHATS_DIFFERENCE_HOME_HELP.DIFFERENCE_TEXT' | translate}}
                  </a>
                </p>
              </div>
              <div class="cea-col-sm-2 cea-col-xs-3">
                <div class="cea-top-margin10" style="float: right;">
                  <button (click)="yesCompare(getPolicyNameByIdYesCompare(policy))"
                          class="btn btn-primary btn-sm">
                    {{'GLOBAL.YES' | translate}}
                  </button>
                </div>
              </div>
            </div>


            <div class="ceadetails">
              <div id="estimatedetails" class="ceatable" ng-style="dynamicTableStyle">
                <table class="table new-cea-table">
                  <ng-container *ngFor="let row of table;let rowIndex=index;">
                    <tr *ngIf="row.key !== 'foundationType' && row.key !== 'frame' && row.key !== 'roofTypeVariable' && row.key !== 'stories'">
                      <td [ngClass]="{'first' : row.class === 'first'}">{{row.label}}</td>
                      <td class="ceavalue" [ngClass]="{'first' : row.valueClass === 'first'}"
                          style="width:150px">
                        <span *ngIf="page===1">{{row["value0"]}}</span>
                        <span *ngIf="(page==2 && perPage===4) || (page==3 && perPage===2) || (page==5 && perPage===1)">{{row["value4"]}}</span>
                        <span *ngIf="(page==3 && perPage===4) || (page==5 && perPage===2) || (page==9 && perPage===1)">{{row["value8"]}}</span>
                        <span *ngIf="(page==2 && perPage===3) || (page==4 && perPage===1)">{{row["value3"]}}</span>
                        <span *ngIf="(page==3 && perPage===3) || (page==4 && perPage===2) || (page==7 && perPage===1)">{{row["value6"]}}</span>
                        <span *ngIf="(page==4 && perPage===3) || (page==10 && perPage===1)">{{row["value9"]}}</span>
                        <span *ngIf="(page==2 && perPage===2) || (page==3 && perPage===1)">{{row["value2"]}}</span>
                        <span *ngIf="page==2 && perPage===1">{{row["value1"]}}</span>
                        <span *ngIf="page==6 && perPage===1">{{row["value5"]}}</span>
                        <span *ngIf="page==8 && perPage===1">{{row["value7"]}}</span>
                      </td>
                      <td class="ceavalue"
                          *ngIf="(((ids.length >
                        1 && page==1) || (ids.length > 5 && page==2) || (ids.length > 9 && page==3)) && perPage===4)
                        || (((ids.length > 1 && page==1) || (ids.length > 4 && page==2) || (ids.length > 7 && page==3)) && perPage===3)
                        || (((ids.length > 1 && page==1) || (ids.length > 3 && page==2) || (ids.length > 5 && page==3) || (ids.length > 7 && page==4) || (ids.length > 9 && page==5)) && perPage===2)"
                        [ngClass]="{'first' : row.valueClass === 'first'}" style="width:150px">
                        <span *ngIf="page==1">{{row["value1"]}}</span>
                        <span *ngIf="(page==2 && perPage===4) || (page==3 && perPage===2)">{{row["value5"]}}</span>
                        <span *ngIf="(page==3 && perPage===4) || (page==5 && perPage===2)">{{row["value9"]}}</span>
                        <span *ngIf="page==2 && perPage===3">{{row["value4"]}}</span>
                        <span *ngIf="(page==3 && perPage===3) || (page==4 && perPage===2)">{{row["value7"]}}</span>
                        <span *ngIf="page==2 && perPage===2">{{row["value3"]}}</span>
                      </td>
                      <td class="ceavalue"
                          *ngIf="(((ids.length >
                          2 && page==1) || (ids.length > 6 && page==2) || (ids.length > 9 && page==3)) && perPage===4)
                          || (((ids.length > 2 && page==1) || (ids.length > 6 && page==2) || (ids.length > 8 && page==3)) && perPage===3)"
                          [ngClass]="{'first' : row.valueClass === 'first'}" style="width:150px">
                          <span *ngIf="page==1">{{row["value2"]}}</span>
                          <span *ngIf="page==2 && perPage===4">{{row["value6"]}}</span>
                          <span *ngIf="page==2 && perPage===3">{{row["value5"]}}</span>
                          <span *ngIf="page==3 && perPage===3">{{row["value8"]}}</span>
                      </td>
                      <td class="ceavalue"
                          *ngIf="((ids.length > 3 && page==1) || (ids.length > 7 && page==2)) && perPage===4"
                          [ngClass]="{'first' : row.valueClass === 'first'}" style="width:150px">
                        <span *ngIf="page==1">{{row["value3"]}}</span>
                        <span *ngIf="page==2">{{row["value7"]}}</span>
                      </td>
                    </tr>
                  </ng-container>
                </table>
              </div>
            </div>

            <div class="tableBtns">
              <a style="color: #1294b2 !important" class="link-color" class="first" [ngClass]="{'noDis': page > 1}"
                 (click)="setPage(1)">&#171;</a><span>&nbsp;</span>
              <a style="color: #1294b2 !important" class="link-color" class="prev" [ngClass]="{'noDis': page > 1}" (click)="setPage(page-1)">&#8249;</a>
              {{page}} {{'COMPARE_SEND.OF' | translate}} {{pages}}
              <a style="color: #1294b2 !important" class="link-color" class="next" [ngClass]="{'noDis': page < pages}"
                 (click)="setPage(page+1)">&#8250;</a><span>&nbsp;</span>
              <a style="color: #1294b2 !important" class="link-color" class="last" [ngClass]="{'noDis': page < pages}" (click)="setPage(pages)">&#187;</a>
            </div>


          </div>
        </div>

        <div id="report-footer-text" *ngIf="showTable">
          <div class="details-text ng-binding ng-hide" style="display: inline-block;"
               ng-show="calculatorSettings.compareAndSendPageSettings.customCallToActionHtml"
               ng-bind-html="trustAsHtml(calculatorSettings.compareAndSendPageSettings.customCallToActionHtml)">
          </div>

          <div class="details-text ng-binding" style="display: inline-block; text-align: justify">
            {{"COMPARE_SEND.TERMS_TEXT" | translate }}
          </div>
        </div>


        <div ng-hide="showEmailForm || showEmailSuccess" class="row estBtns cea-top-margin15 text-center">
          <div class="cea-col-lg-3 cea-col-md-6 cea-col-sm-6 cea-col-xs-6 cea-col-xxs-12">
            <button class="btn btn-primary btn-block" id="compare-back-btn" [disabled]="!showTable" (click)="goBack()">
              <em class="fas fa-arrow-left"></em>
              {{'GLOBAL.BACK_BUTTON' | translate }}
            </button>
          </div>

          <div class="cea-col-lg-3 cea-col-md-6 cea-col-sm-6 cea-col-xs-6 cea-col-xxs-12">

            <button [disabled]="!showTable" (click)="openHelperEmailPopup($event)"
                    class="btn btn-primary btn-block" id="compare-email-btn">
              <em class="fas fa-envelope"></em>
              {{'COMPARE_SEND.EMAIL_BUTTON' | translate }}
            </button>

          </div>

          <div class="cea-col-lg-3 cea-col-md-6 cea-col-sm-6 cea-col-desktop-only">
            <button [disabled]="!showTable" (click)="print($event)" class="btn btn-primary btn-block" id="compare-print-btn">
              <em class="fas fa-print"></em>
              {{'COMPARE_SEND.PRINT_BUTTON' | translate }}
            </button>
          </div>
          <div class="cea-col-lg-3 cea-col-md-6 cea-col-sm-6 cea-col-xs-6 cea-col-xxs-12">
            <button [disabled]="!showTable" class="btn btn-primary btn-block" (click)="download($event)" id="compare-download-btn">
              <em class="fas fa-print"></em>
              {{'COMPARE_SEND.DOWNLOAD_BUTTON' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div style="display: none; font-size: 12pt;">
      <div class="cea-visible-lg">
        LG
      </div>
      <div class="cea-visible-md">
        MD
      </div>
      <div class="cea-visible-sm">
        SM
      </div>
      <div class="cea-visible-xs">
        XS
      </div>
      <div class="cea-visible-xss cea-visible-xxs">
        XXS
      </div>
      {{screenWidth}}
    </div>
  </div>
</div>
