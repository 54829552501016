import { Component, OnInit } from '@angular/core';
import { TranslationService } from './../services/translation.service';
import { DraftNameService, LIVE_VERSION } from './../services/draftname.service';
import { Translation } from '../models/translation';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NewDraftComponent } from './../components/new-draft/new-draft.component';
import { MatDialog } from '@angular/material/dialog';
import { MsalService } from '@azure/msal-angular';
import { HistoryComponent } from '../components/history/history.component';

export const MORE_HISTORY_VERSION = "__more";


@Component({
    selector: 'app-translation',
    templateUrl: './translation.component.html',
    styleUrls: ['./translation.component.scss']
})
export class TranslationComponent implements OnInit {
    translations: Translation[] = [];
    current: Translation;
    currentLive: Translation;
    drafts: string[] = [];
    history: string[] = [];
    shortHistory: string[] = [];
    currentDraft: string = "";
    previousDraft: string = "";
    isLoading: boolean = true;
    historyMax: number = 20;
    me: any;
    

    constructor(private translationService: TranslationService,
        private authService: MsalService,
        private draftnameService: DraftNameService,
        private readonly dialogCreateDashboard: MatDialog,
        private snackBar: MatSnackBar) {
    }

    ngOnInit(): void {
        this.authService.instance.handleRedirectPromise().then(response => {
            if (!this.me) {
                this.translationService.Me().subscribe(response => {
                    this.me = this.getUserName(response);
                });
            }

            this.translationService.Drafts().subscribe(response => {
                this.drafts = response;
            });

            this.getHistory();

            this.getTranslations('');
        })
    }

    getTranslations(draft: string): void {
        this.isLoading = true;
        this.translationService.Get(draft)
            .subscribe(translations => {
                this.translations = this.translationService.Paths("", translations);
                setTimeout((ctx) => {
                    ctx.current = null;
                    ctx.currentLive = null;
                    this.isLoading = false;
                }, 1000, this);
                
            });
    }

    onTranslationUpdate(translation: Translation) {
        this.isLoading = true;
        this.translationService.Update(this.currentDraft, translation).subscribe(result => {
            if (result) {
                this.showMessage("Saved", "Close");
                this.updateLocal(translation.path, translation, this.translations);
                this.onTranslationSelect(this.current);
            }
            else {
                this.showMessage("Error", "ERROR: Draft is owned by user: " + this.getOwner());
            }
            this.isLoading = false;
        });
    }

    onTranslationSelect(translation: Translation) {
        this.current = translation;
        this.translationService.Find("", this.current.path).subscribe(response => {
            this.currentLive = response;
        });

        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    showMessage(message: string, action: string) : void {
        this.snackBar.open(message, action);
    }

    updateLocal(path: string, translation: Translation, translations: Translation[]) {
        const parts = path.indexOf('.') > -1 ? path.split('.') : [path];
        const first = parts[0];

        for (let trans of translations) {
            if (first.toLowerCase() === trans.label.toLowerCase()) {
                trans.isModified = true;
                if (parts.length === 1) {
                    trans.english = translation.english;
                    trans.spanish = translation.spanish;
                    trans.chinese = translation.chinese;
                    return;
                }
                else {
                    parts.shift();
                    const subPath = parts.join('.');
                    this.updateLocal(subPath, translation, trans.children);
                    return;
                }
            }
        }
    }

    onSelection(val: string) {
        if (val === MORE_HISTORY_VERSION) {
            this.displayHistory();
            return;
        }
        this.currentDraft = val === LIVE_VERSION ? '' : val;
        this.getTranslations(this.currentDraft);
    }

    createDraft() {
        const dialogRefSwitch = this.dialogCreateDashboard.open(
            NewDraftComponent,
            {
                width: '400px',
                position: { top: '1%', left: '20%' },
                panelClass: 'create-dialog',
                data: { source: this.currentDraft }
            }
        );

        dialogRefSwitch.afterClosed().subscribe(result => {
            if (result) {
                this.isLoading = true;
                this.translationService.Draft(result, this.currentDraft).subscribe(response => {
                    this.translationService.Drafts().subscribe(response1 => {
                        this.drafts = response1;
                        //only one draft allowed, first response is to be selected
                        this.resetSelect(response1[0]);
                        this.isLoading = false;
                    });

                })
            }
        });
    }

    displayHistory() {
        const dialogRefSwitch = this.dialogCreateDashboard.open(
            HistoryComponent,
            {
                width: '400px',
                position: { top: '1%', left: '20%' },
                panelClass: 'history-dialog',
                data: { history: this.history }
            }
        );

        dialogRefSwitch.afterClosed().subscribe(result => {
            if (result) {
                this.addHistory(result);

                setTimeout(ctx => {
                    (document.getElementById('draft') as HTMLSelectElement).value = result;
                    ctx.onSelection(result);
                }, 1000, this)
            }
            else {
                //change UI to match currentDraft
                (document.getElementById('draft') as HTMLSelectElement).value = this.currentDraft === LIVE_VERSION ? '' : this.currentDraft;
            }
        });
    }

    addHistory(draft: string) {
        let eSel = (document.getElementById('draft') as HTMLSelectElement);
        if (this.isHistoryOption(draft, eSel)) {
            eSel.value = draft;
        }
        else {
            eSel.options[eSel.options.length - 1].remove();

            eSel.options.add(new Option(this.draftnameService.DisplayName(draft), draft));
            eSel.options.add(new Option('More...', MORE_HISTORY_VERSION));
        }
    }

    isHistoryOption(draft: string, sel: HTMLSelectElement) {
        for (let i = 0; i < sel.options.length; i++) {
            if (sel.options[i].value === draft) {
                return true;
            }
        }

        return false;
    }


    onCreate() {
        this.createDraft();
    }

    onPreview() {
        window.open('/home?lv=' + this.currentDraft, "_blank");
    }

    onPublish() {
        if (confirm("Are you sure you want to merge " + this.currentDraft + "into the Live version?")) {
            this.isLoading = true;
            this.translationService.Merge(this.currentDraft).subscribe(response => {
                this.getHistory();

                this.translationService.Drafts().subscribe(response => {
                    this.drafts = response;
                    this.currentDraft = '';
                    this.isLoading = false;
                    this.resetSelect(this.currentDraft);
                });
            },
                err => {
                    this.showMessage("Error", "ERROR: Draft is owned by user: " + this.getOwner());
                });
        } 
    }

    onRemove() {
        if (confirm("Are you sure you want to delete " + this.currentDraft + "?")) {
            this.translationService.Remove(this.currentDraft).subscribe(response => {
                setTimeout(ctx => {
                    ctx.translationService.Drafts().subscribe(drafts => {
                        ctx.drafts = drafts;
                        ctx.currentDraft = '';
                        ctx.resetSelect(ctx.currentDraft);
                    })
                }, 2000, this);
            },
            err => {
                    this.showMessage("Error", "ERROR: Draft is owned by user: " + this.getOwner());
            });
        } 
    }

    onRequest() {
        if (confirm("You want to take over the draft?")) {
            this.translationService.Request(this.currentDraft).subscribe(response => {
                setTimeout(ctx => {
                    ctx.translationService.Drafts().subscribe(drafts => {
                        ctx.drafts = drafts;
                        ctx.currentDraft = drafts[0];
                        ctx.resetSelect(ctx.currentDraft);
                    })
                }, 2000, this);
            })
        }
    }

    isMain(): boolean {
        return this.testIsMain(this.currentDraft);
    }

    testIsMain(draft: string): boolean {
        return !draft || draft === "" || draft === LIVE_VERSION;
    }

    isHistory(): boolean {
        if (this?.currentDraft) {
            return this.currentDraft.startsWith("hist_");
        }

        return false;
    }

    isDraft(): boolean {
        return !this.isMain() && !this.isHistory();
    }

    canPreview(): boolean {
        //possible: may not want to allow history to preview
        return true;
    }

    canCreate(): boolean {
        return (this.isMain() || this.isHistory())
            && this.drafts.length < 1;
    }

    canPublish(): boolean {
        return this.isDraft() && this.draftnameService.HasWriteAccess(this.me, this.currentDraft);
    }

    canRemove(): boolean {
        return this.isDraft() && this.draftnameService.HasWriteAccess(this.me, this.currentDraft);
    }

    canRequest(): boolean {
        return this.isDraft() && !this.draftnameService.HasWriteAccess(this.me, this.currentDraft);
    }

    isEditable(): boolean {
        return this.isDraft() && this.draftnameService.HasWriteAccess(this.me, this.currentDraft);
    }

    isOwnerMsg(): boolean {
        return this.isDraft() && !this.draftnameService.HasWriteAccess(this.me, this.currentDraft);
    }

    displayName(val: string): string {
        return this.draftnameService.DisplayName(val);
    }

    getUserName(me: any) {
        const parts = me["mail"].toString().split("@");
        return parts[0].toLowerCase();
    }

    public getOwner() {
        return this.draftnameService.GetUserName(this.currentDraft);
    }

    getHistory() {
        this.translationService.History().subscribe(response => {
            let res = [];
            response.forEach(x => {
                res.push({
                    name: x,
                    date: this.draftnameService.HistoryDate(x)
                });
            });

            res.sort((a, b) => {
                return b.date - a.date;
            });

            this.history = res.map(y => {
                return y.name;
            });

            this.shortHistory = this.history.slice(0, this.historyMax);
        });
    }

    resetSelect(draft) {
        setTimeout(ctx => {
            ctx.currentDraft = draft;
            (document.getElementById('draft') as HTMLInputElement).value = ctx.currentDraft ? ctx.currentDraft : LIVE_VERSION;

            ctx.getTranslations(draft);
        }, 500, this);
    }
}
