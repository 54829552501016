import { Component, NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import {HomeownerComponent} from "./homeowner/homeowner.component";
import {RenterComponent} from "./renter/renter.component";
import {CondoComponent} from "./condo/condo.component";
import {MobilehomeComponent} from "./mobilehome/mobilehome.component";
import {HomeComponent} from "./home/home.component";
import {CalculationsComponent} from "./calculations/calculations.component";
import {CompareComponent} from "./compare/compare.component";

const routes: Routes = [
  {
    path: "", component: HomeComponent,
  },
  {
      path: "homeowner/general", component: HomeownerComponent,
  },
  {
      path: "homeowner/questions", component: HomeownerComponent,
  },
  {
    path: "renter/general", component: RenterComponent,
  },
  {
    path: "condo/general", component: CondoComponent,
  },
  {
      path: "mobilehome/general", component: MobilehomeComponent,
  },
  {
      path: "mobilehome/questions", component: MobilehomeComponent,
  },
  {
    path: "renter/calculations/:moduleType", component: CalculationsComponent,
  },
  {
    path: "condo/calculations/:moduleType", component: CalculationsComponent,
  },
  {
    path: "mobilehome/calculations/:moduleType", component: CalculationsComponent,
  },
  {
    path: "homeowner/calculations/:moduleType", component: CalculationsComponent,
  },
  {
    path: "compare", component: CompareComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppMainRoutingModule { }
