import { NestedTreeControl } from '@angular/cdk/tree';
import { EventEmitter, Input, Output, OnChanges, Component, OnInit } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Translation } from '../../models/translation';


@Component({
    selector: 'app-tree-folder',
    templateUrl: './tree-folder.component.html',
    styleUrls: ['./tree-folder.component.scss']
})
export class TreeFolderComponent implements OnInit, OnChanges {
    @Input('translations') translations: Translation[];
    @Input("live") live: Translation;
    @Output() emitSelectEvent = new EventEmitter<Translation>();
    treeControl = new NestedTreeControl<Translation>(node => node.children);
    dataSource = new MatTreeNestedDataSource<Translation>();

    ngOnInit(): void {
        this.dataSource.data = this.translations;
    }

    ngOnChanges() : void {
        this.dataSource.data = this.translations;
        setTimeout(() => {
            if (this.live?.path) {
                this.selectNode(this.live.path, this.dataSource.data);
            }
        },2000)
    }

    hasChild = (_: number, node: Translation) => !!node.children && node.children.length > 0;

    selectTranslation(node) {
        this.emitSelectEvent.emit(node);
    }

    isHighlight(node) {
        if (node && this.live) {
            return node.path === this.live.path;
        }
    }

    onView() {
        window.open('/home?lang=kv', "_blank");
    }

    selectNode(path: string, data: Translation[]): void {
        if (!path) {
            return;
        }

        const parts = path.indexOf('.') > -1 ? path.split('.') : [path];
        const first = parts[0];

        for (let trans of data) {
            if (first.toLowerCase() === trans.label.toLowerCase()) {
                this.treeControl.expand(trans);
                if (parts.length === 1) {
                    return;
                }
                else {
                    parts.shift();
                    const subPath = parts.join('.');
                    this.selectNode(subPath, this.treeControl.getDescendants(trans));
                    return;
                }
            }
        }
    }
}
