<div class="modal-header" style="border:none">
    <button type="button" class="close" (click)="closeModal('close')" aria-label="Close">&times;</button>
</div>

<div class="modal-body cea-home">
    <div *ngIf="!showSuccess">
        <div class="header-container text-center">
            <h2>{{'EMAIL.TITLE' | translate }}</h2>
        </div>

        <div class="row compare-area blue-key">
            <div class="cea-col-md-7 cea-col-lg-7 cea-col-sm-7">
                <div class="cea-visible-mod-lg cea-visible-mod-md cea-visible-mod-sm cea-visible-mod-xs cea-hidden-mod-xxs cea-hidden-mod-xss">
                    <div class="body-copy" style="margin: 10px 0;">
                        {{'EMAIL.COMPARE_INSTRUCTIONS' | translate }}
                    </div>

                    <table class="estimate-group-table table-striped table-hover">
                        <thead>
                            <tr>
                                <th class="select-column" id="select-column">
                                    {{'COMPARE_SEND.COMPARE_TABLE.SELECT_HEADER' | translate }}
                                </th>
                                <th class="monthly-column" id="monthly-column">
                                    {{'COMPARE_SEND.COMPARE_TABLE.MONTHLY_HEADER' | translate }}
                                </th>
                                <th class="annually-column" id="annually-column">
                                    {{'COMPARE_SEND.COMPARE_TABLE.ANNUALLY_HEADER' | translate }}
                                </th>
                                <th class="policy-column" id="policy-column">{{'COMPARE_SEND.COMPARE_TABLE.POLICY_TYPE_HEADER' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let q of quotes; let i = index">
                                <td class="select-column" ng-class="{selected: quote.selected}"
                                    (click)="selectCalculation(i)" [ngClass]="{'selected': ids.indexOf(i) > -1}">
                                    {{i + 1}}
                                </td>
                                <td class="monthly-column">
                                    ${{q.MonthlyPremium.toFixed(2) }}
                                </td>
                                <td class="annually-column">
                                    ${{q.AnnualPremium.toFixed(2)}}
                                </td>
                                <td class="type-column">
                                    {{q.PolicyType }}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="row findAgent">
                        <div class="cea-col-sm-12">
                            <a class="btn btn-block btn-accent" href="{{findAgentUrl | lowercase}}" target="_blank" rel="noopener"
                               (click)="trackAgentClick(findAgentUrl)">{{'COMPARE_SEND.FIND_AGENT_BUTTON' | translate }}</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="cea-visible-mod-xxs cea-visible-mod-xss cea-hidden-mod-lg cea-hidden-mod-md cea-hidden-mod-sm cea-hidden-mod-xs blue-key list-area"
                 style="margin: 0 15px;">

                <div class="body-copy text-center" style="margin-bottom: 15px;">
                    {{'EMAIL.COMPARE_INSTRUCTIONS' | translate }}
                </div>
                <div style="border: 1px solid #777">
                    <div class="panel estimate-panel" *ngFor="let q of quotes; let i = index">
                        <button class="btn btn-default panel-select-button btn-block" (click)="selectCalculation(i)"
                                [ngClass]="{'active': ids.indexOf(i) > -1}">
                            {{i + 1}}
                        </button>
                        <div class="panel-body">
                            <div class="panel-field-caption">
                                {{'COMPARE_SEND.COMPARE_TABLE.MONTHLY_HEADER' | translate }}
                            </div>
                            <div class="panel-field-value"> ${{q.MonthlyPremium | number:'1.2-2'  }}</div>
                            <div class="panel-field-caption">
                                {{'COMPARE_SEND.COMPARE_TABLE.ANNUALLY_HEADER' | translate }}
                            </div>
                            <div class="panel-field-value"> ${{q.AnnualPremium  | number:'1.2-2'}}</div>
                            <div class="panel-field-caption">
                                {{'COMPARE_SEND.COMPARE_TABLE.POLICY_TYPE_HEADER' | translate }}
                            </div>
                            <div class="panel-field-value"> {{q.PolicyType}}</div>
                        </div>
                    </div>
                </div>
                <div style="margin-top: 20px;">
                    <a class="btn btn-block btn-accent" href="{{findAgentUrl | lowercase}}" target="_blank" rel="noopener"
                       (click)="trackAgentClick(findAgentUrl)">{{'COMPARE_SEND.FIND_AGENT_BUTTON' | translate }}</a>
                </div>

            </div>

            <div class="cea-col-md-5 cea-col-lg-5 cea-col-sm-5" style="padding-left: 15px; padding-right: 15px;">
                <div class="cea-visible-mod-xxs cea-visible-mod-xss cea-hidden-mod-lg cea-hidden-mod-md cea-hidden-mod-sm cea-hidden-mod-xs" style="margin-top: 30px;">
                </div>
                <div>


                    <div class="body-copy" style="text-align: left">
                        {{'EMAIL.INSTRUCTIONS' | translate }}
                    </div>
                    <form novalidate id="mailForm" name="mailForm" #emailForm="ngForm">
                        <div class="form-group required">
                            <label for="sendTo" class="cea-visible-mod-xxs-block" trackVisibility (visibile)="mobileVisible($event)">{{'EMAIL.DESC' | translate }}</label>
                            <textarea id="sendTo" name="to" class="form-control" [(ngModel)]="email.sendTo" required
                                      (ngModelChange)="validateMultipleEmail()"
                                      placeholder="{{'EMAIL.DESC' | ceaPlaceholder: !isMobileView | translate}}">
                            </textarea>
                            <div *ngIf="showErrorSentToEmail" style="margin-left: -10px !important;"
                                 class="ng-isolate-scope alert alert-danger" role="alert">
                                {{"ERRORS.INVALID_EMAIL_FORMAT" | translate}}
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="to" class="cea-visible-mod-xxs-block">{{'EMAIL.TO' | translate }}</label>
                            <input id="to" name="sendTo" type="text"
                                   placeholder="{{'EMAIL.TO' | ceaPlaceholder: !isMobileView | translate }}" class="form-control"
                                   [(ngModel)]="email.to" cea-validator autocorrect="off" autocapitalize="none"
                                   (ngModelChange)="validateSingleEmail()">
                            <div style="margin-left: -10px !important;" *ngIf="showErrorToEmail"
                                 class="ng-isolate-scope alert alert-danger" role="alert">
                                {{"ERRORS.INVALID_EMAIL_FORMAT" | translate}}
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="firstName" class="cea-visible-mod-xxs-block">{{'EMAIL.FIRST_NAME' | translate }}</label>
                            <input id="firstName" name="firstName" type="text"
                                   placeholder="{{'EMAIL.FIRST_NAME' | ceaPlaceholder: !isMobileView | translate }}" class="form-control"
                                   [(ngModel)]="email.first">
                        </div>
                        <div class="form-group">
                            <label for="lastName" class="cea-visible-mod-xxs-block">{{'EMAIL.LAST_NAME' | translate }}</label>
                            <input id="lastName" name="lastName" type="text"
                                   placeholder="{{'EMAIL.LAST_NAME' | ceaPlaceholder: !isMobileView | translate }}" class="form-control"
                                   [(ngModel)]="email.last">
                        </div>
                        <div class="form-group">
                            <label for="message" class="cea-visible-mod-xxs-block">{{'EMAIL.DEFAULT_CONTENT' | translate }}</label>
                            <textarea id="message" name="message" type="text"
                                      placeholder="{{'EMAIL.DEFAULT_CONTENT' | ceaPlaceholder: !isMobileView | translate }}"
                                      class="form-control" rows="3" [(ngModel)]="email.message"></textarea>
                        </div>
                        <div class="cea-visible-mod-lg cea-visible-mod-md cea-visible-mod-sm cea-visible-mod-xs cea-hidden-mod-xxs cea-hidden-mod-xss text-right">
                            <button type="button" (click)="closeModal('close')" class="btn btn-link text-uppercase"
                                    style="font-size: .9em; font-weight: normal;">
                                <u>{{'COMPARE_SEND.CANCEL' | translate }}</u>
                            </button>
                            <button type="button" class="btn btn-primary text-uppercase" (click)="sentEmail()"
                                    style="font-size: .9em; font-weight: normal;"
                                    [disabled]="!emailForm.form.valid || !custLoader || showErrorToEmail || showErrorSentToEmail">
                                <em *ngIf="showSpin" class="fa fa-spinner fa-spin"></em>
                                {{send}}
                            </button>
                        </div>

                        <div class="cea-visible-mod-xxs cea-visible-mod-xss cea-hidden-mod-lg cea-hidden-mod-md cea-hidden-mod-sm cea-hidden-mod-xs">
                            <div class="cea-col-xxs-12 cea-col-xss-12">
                                <button button-click="sendEmail(mailForm)" class="btn btn-block btn-primary "
                                        ng-disabled="mailForm.$invalid">
                                </button>
                                <button type="button" (click)="sentEmail()" class="btn btn-link text-uppercase"
                                        style="font-size: .9em; font-weight: normal;">
                                    <u>{{send}}</u>
                                </button>
                                <button type="button" (click)="closeModal('close')" class="btn btn-link text-uppercase"
                                        style="font-size: .9em; font-weight: normal;">
                                    <u>{{'COMPARE_SEND.CANCEL' | translate }}</u>
                                </button>

                            </div>

                        </div>
                    </form>


                </div>
            </div>
        </div>
    </div>

    <div *ngIf="showSuccess">
        <div class="row">
            <h2 class="text-center container">{{'EMAIL.SUCCESS.TITLE' | translate }}</h2>
            <div class="body-copy text-center container margin-bottom-lg pb-5">
                {{'EMAIL.SUCCESS.TEXT' | translate }}
            </div>
        </div>

        <div class="blue-key cea-visible-mod-lg cea-visible-mod-md cea-visible-mod-sm cea-hidden-mod-xs cea-hidden-mod-xxs cea-hidden-mod-xss">
            <div class="row">
                <div class="cea-col-sm-4">
                    <div class="panel">
                        <div class="panel-body text-center pull-down">
                            <h2>{{'EMAIL.SUCCESS_INSURER.TITLE' | translate }}</h2>
                            <p class="body-copy">
                                {{'EMAIL.SUCCESS_INSURER.TEXT' | translate }}
                            </p>
                        </div>
                        <a style="min-height: 45px;"
                           class="btn btn-primary primary-button btn-sm btn-block button-pull-down"
                           href="{{eqaUrl | lowercase}}{{'GLOBAL.PARTICIPATING_INSURER_URL' | translate | lowercase}}"
                           target="_parent">{{'EMAIL.SUCCESS_INSURER.LINK' | translate }}</a>
                    </div>
                </div>
                <div class="cea-col-sm-4">
                    <div class="panel">
                        <div class="panel-body text-center pull-down">
                            <h2>{{'EMAIL.STRENGTHEN_HOME.TITLE' | translate }}</h2>
                            <p class="body-copy">
                                {{'EMAIL.STRENGTHEN_HOME.TEXT' | translate }}
                            </p>
                        </div>
                        <a style="min-height: 45px;"
                           class="btn btn-primary primary-button btn-sm btn-block button-pull-down"
                           href="{{eqaUrl | lowercase}}{{'GLOBAL.RISK_PREPAREDNESS_URL' | translate | lowercase}}"
                           target="_parent">{{'EMAIL.STRENGTHEN_HOME.LINK' | translate }}</a>
                    </div>
                </div>
                <div class="cea-col-sm-4">
                    <div class="panel">
                        <div class="panel-body pull-down text-center">
                            <h2>{{'EMAIL.ANOTHER_PREMIUM.TITLE' | translate }}</h2>
                            <p class="body-copy">
                                {{'EMAIL.ANOTHER_PREMIUM.TEXT' | translate }}
                            </p>
                        </div>
                        <a style="min-height: 45px;" (click)="startOver()"
                           class="btn btn-primary primary-button btn-sm btn-block button-pull-down">{{'EMAIL.ANOTHER_PREMIUM.LINK' | translate }}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="blue-key cea-visible-mod-xs cea-visible-mod-xxs cea-visible-mod-xss cea-hidden-mod-lg cea-hidden-mod-md cea-hidden-mod-sm">
            <div class="row">
                <div class="panels-row">
                    <div class="panel">
                        <div class="panel-body">
                            <h2>{{'EMAIL.SUCCESS_INSURER.TITLE' | translate }}</h2>
                            <p class="body-copy">
                                {{'EMAIL.SUCCESS_INSURER.TEXT' | translate }}
                            </p>
                        </div>
                        <a class="btn btn-primary primary-button btn-lg btn-block" href="{{eqaUrl | lowercase}}{{'GLOBAL.PARTICIPATING_INSURER_URL' | translate | lowercase}}"
                           target="_parent">{{'EMAIL.SUCCESS_INSURER.LINK' | translate }}</a>
                    </div>
                </div>
                <div class="panels-row">
                    <div class="panel">
                        <div class="panel-body">
                            <h2>{{'EMAIL.STRENGTHEN_HOME.TITLE' | translate }}</h2>
                            <p class="body-copy">
                                {{'EMAIL.STRENGTHEN_HOME.TEXT' | translate }}
                            </p>
                        </div>
                        <a class="btn btn-primary primary-button btn-lg btn-block" href="{{eqaUrl | lowercase}}{{'GLOBAL.RISK_PREPAREDNESS_URL' | translate | lowercase}}"
                           target="_parent">{{'EMAIL.STRENGTHEN_HOME.LINK' | translate }}</a>
                    </div>
                </div>
                <div class="panels-row">
                    <div class="panel">
                        <div class="panel-body">
                            <h2>{{'EMAIL.ANOTHER_PREMIUM.TITLE' | translate }}</h2>
                            <p class="body-copy">
                                {{'EMAIL.ANOTHER_PREMIUM.TEXT' | translate }}
                            </p>
                        </div>
                        <a class="btn btn-primary primary-button btn-lg btn-block"
                           (click)="startOver()">{{'EMAIL.ANOTHER_PREMIUM.LINK' | translate }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div style="display: none;">
        <div class="cea-visible-mod-lg">
            LG
        </div>
        <div class="cea-visible-mod-md">
            MD
        </div>
        <div class="cea-visible-mod-sm">
            SM
        </div>
        <div class="cea-visible-mod-xs">
            XS
        </div>
        <div class="cea-visible-mod-xxs">
            XXS
        </div>

    </div>
